.box {
  margin: 15px;
  margin-top: 40px;
}

.About {
  margin-left: 20px;
  padding: 20px;
}

.br {
  margin-top: 20px;
}

.head {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(85, 85, 85);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.heading {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

.link {
  color: #333333;
}

@keyframes text {
  from {
    transform: translateX(-30px);
    opacity: 0.5;
  }
}

@media (max-width: 992px) {
  .box {
    text-align: center;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
