.ContactForm {
    margin: 15px;
    margin-top: 40px;
  }
  
  .ContactForm h2 {
    margin-bottom: 20px;
    margin-left: 30px;
    letter-spacing: 5px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .ContactForm form {
    margin-left: 20px;
    padding: 20px;
  }
  
  .ContactForm .form-group {
    margin-bottom: 20px;
  }
  
  .ContactForm label {
    display: block;
    margin-bottom: 5px;
    color: rgb(85, 85, 85);
  }
  
  .ContactForm input,
  .ContactForm textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }
  
  .ContactForm button {
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .ContactForm button:hover {
    background-color: #555555;
  }
  