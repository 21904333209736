@import url('https://fonts.googleapis.com/css?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap');
.sidebar {
  height: 100vh;
  /* margin-top: -50px; */
  width: 100%;
  background-color: #f2f3f7;
  overflow-y: scroll;
  text-align: center;
}
.sidebar > h1 {
  padding-top: 20px;
  text-align: center;
}
.tagtop {
  margin-top: 40px;
}

.icon-black {
  color: black !important;
}

.name-font {
  font-family: 'Lora', serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000;
}

.h1_links {
  text-decoration: none;
  font-family: 'Aclonica', sans-serif;
  color: #000;
  font-size: 32px;
}
.sidebar > p {
  margin-top: 20px;
  text-align: center;
}

.sidebar-nav {
  margin-left: -30px;
  margin-top: 30px;
  text-align: center;
}
.sidebar-nav-items {
  margin-bottom: 15px;
}
.sidebar > img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: 10px;
}
.links {
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Comfortaa', cursive;
  color: #000;
  font-size: larger;
  line-height: 1.4rem;
}
.sidebar-nav-icons {
  margin-bottom: 15px;
}

.flip-card-back {
  margin-top: 50px;
}
.fa-lg {
  text-decoration: none;
  color: red;
}

.links:hover {
  /* color:rgb(121, 189, 252); */
  color: rgb(121, 189, 252);
  text-decoration: underline;
}
.fas:hover {
  color: rgb(41, 152, 255);
  text-decoration: underline;
}
ul {
  list-style: none;
}
.h1_links:hover {
  color: #000;
}
/*//////////////media ////////////////////*/
@media (max-width: 980px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 29px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 28px;
  }
  .links {
    display: none;
  }
  .fas {
    transform: scale(1.3);
    margin: 5px 0px;
  }
  .gmail {
    font-size: 16px;
    font-weight: normal;
  }
}

@media (max-width: 370px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 24px;
  }
  .links {
    font-size: 16px;
  }
  .fas {
    transform: scale(1.1);
    margin: 5px 0px;
  }
}
